import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { getQueryString } from '../../helpers/url';
import { WelletContext } from '../../context/WelletContext';
import { useDispatch, useSelector } from 'react-redux';
import { errorText } from '../../helpers/login';
import { useTranslation } from 'react-i18next';
import { setCanRefresh } from '../../actions/appActions';
import Header from '../../components/Common/Header/Header';
import ContentApp from '../../components/ContentApp';
import Button from '../../components/Common/Button/Button';
import useForm from '../../helpers/useForm';
import ErrorBox from '../../components/Common/ErrorBox/ErrorBox';
import { Spinner } from '../../components/Common/Spinner/Spinner';
import 'react-phone-number-input/style.css';
import InputFormBorder from '../../components/Common/InputFormBorder/InputFormBorder';
import styles from './SignUp.module.css';
import IndustryRoleSelectorRosaNegra from '../../components/Common/IndustryRoleSlector/IndustryRoleSelectorRosaNegra';

const SignUpEmailValidationScreenRosanegra = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const phone = getQueryString('phone');
  const token = getQueryString('token');
  const countryCode = getQueryString('countryCode');
  const welletContext = useContext(WelletContext);
  const tenantId = useSelector((state) => state.app.tenantId);
  const dispatch = useDispatch();
  const [worked, setWorked] = useState(null);
  const [familyWorked, setFamilyWorked] = useState(null);

  const [verifyingUser, setVerifyingUser] = useState(false);
  const [errorUser, setErrorUser] = useState(undefined);
  const [selectedIndustry, setSelectedIndustry] = useState(t('signUp.selectSector'));
  const [selectedRp, setSelectedRp] = useState({ label: t('signUp.selectRp') });
  const [selectedCity, setSelectedCity] = useState({ label: t('signUp.selectCity') });
  const hasNoRp = selectedRp.value === '0';
  const requiresRP = useSelector((state) => state.app?.currentMarket?.requiresRP);
  useEffect(() => {
    dispatch(setCanRefresh(false));
  }, []);

  // redirigir segun tenant === .env

  function validate(values) {
    let errors = {};
    if (!values.email) {
      errors.email = t('emailError.EMPTY_EMAIL');
    } else if (!values.email.includes('@')) {
      errors.email = t('emailError.EMAIL_@');
    } else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(values.email)) {
      errors.email = t('emailError.INVALID_EMAIL');
    }
    if (values.email && !values.emailConfirmation) {
      errors.emailConfirmation = t('emailError.EMPTY_EMAIL');
    } else if (values.email !== values.emailConfirmation) {
      errors.emailConfirmation = t('emailError.EMAIL_NOT_MATCH');
    }
    if (!values.industry || values.industry === t('selectSector')) {
      errors.industry = t('errorSignUp.NO_INDUSTRY');
    }
    if (!values.role) {
      errors.role = t('errorSignUp.NO_ROLE');
    }
    if (!values.company) {
      errors.company = t('errorSignUp.NO_COMPANY');
    }

    if (values.industry === 'Hotel') {
      if (!values.corporateEmail) {
        errors.corporateEmail = t('errorSignUp.NO_CORPORATE_EMAIL');
      }
    } else {
      if (values.industry && !values.socialNetworkCompany) {
        errors.socialNetworkCompany = t('errorSignUp.NO_SOCIAL_NETWORK');
      }

      if (values.industry && !values.officeAddressCompany) {
        errors.officeAddressCompany = t('errorSignUp.NO_OFFICE_ADDRESS');
      }

      if (values.industry && !values.webCompany) {
        errors.webCompany = t('errorSignUp.NO_WEB');
      }
    }

    if (requiresRP) {
      if (!values.rp) errors.rp = 'Seleccione RP';

      if (hasNoRp && !values.city) errors.city = 'Seleccione tu ciudad de operacion';
    }

    if (worked === null) {
      errors.worked = 'Seleccione si trabajo en Grupo RosaNegra';
    }

    if (familyWorked === null) {
      errors.familyWorked = 'Seleccione si un familiar trabajo en Grupo RosaNegra';
    }

    return errors;
  }
  const sendForm = async () => {
    setVerifyingUser(true);
    try {
      const result = await welletContext.apis.tickets.post(`users/Available`, {
        email: values.email,
        tenantId: tenantId,
      });

      if (result.data.isAvailable) {
        errorText(result.data.availableMessage, setErrorUser, t);
      } else {
        let query =
          '?phone=' +
          phone +
          '&countryCode=' +
          countryCode +
          '&email=' +
          values.email +
          '&industry=' +
          values.industry +
          '&role=' +
          values.role +
          '&company=' +
          values.company +
          '&familyWorked=' +
          familyWorked +
          '&workedBefore=' +
          worked +
          (values.corporateEmail ? '&corporateEmail=' + values.corporateEmail : '') +
          (values.officeAddressCompany
            ? '&officeAddressCompany=' + values.officeAddressCompany
            : '') +
          (values.webCompany ? '&webCompany=' + values.webCompany : '') +
          (values.socialNetworkCompany
            ? '&socialNetworkCompany=' + values.socialNetworkCompany
            : '') +
          '&token=' +
          token;

        if (requiresRP) {
          query += '&rp=' + values.rp + '&city=' + values.city;
        }

        history.push({
          pathname: '/sign-up-create-password',
          search: query,
        });
      }

      setVerifyingUser(false);
    } catch (error) {
      console.error(error);
      setVerifyingUser(false);
    }
  };

  const onChange = (e) => {
    setErrorUser('');
    handleChange(e);
  };

  const { values, errors, handleChange, handleSubmit } = useForm(sendForm, validate);

  return (
    <>
      <Header />
      <ContentApp background={true}>
        <div className=''>
          <div className='font-medium bold text-white' style={{ lineHeight: '1.6rem' }}>
            {t('enterEmail')}
          </div>
          <div className='mt-2 text-secondary subtitle' style={{ lineHeight: '1.3rem' }}>
            {t('signUp.subtitle3')}
          </div>
          <form onSubmit={handleSubmit}>
            <div className='mt-4 font-normal'>
              <InputFormBorder
                name='email'
                type='email'
                value={values.email || ''}
                error={errors.email}
                label={t('E-mail')}
                placeholder={t('enterEmail')}
                onChange={onChange}
                forceLowecase={true}
              />
            </div>
            <div className='mt-4'>
              <InputFormBorder
                name='emailConfirmation'
                type='email'
                value={values.emailConfirmation || ''}
                error={errors.emailConfirmation}
                label={t('signUp.confirmEmail')}
                placeholder={t('reEnterEmail')}
                onChange={onChange}
                forceLowecase={true}
              />
            </div>
            <div className='mt-4'>
              <IndustryRoleSelectorRosaNegra
                handleChange={onChange}
                selectedIndustry={selectedIndustry}
                setSelectedIndustry={setSelectedIndustry}
                selectedRp={selectedRp}
                setSelectedRp={setSelectedRp}
                selectedCity={selectedCity}
                setSelectedCity={setSelectedCity}
                values={values}
                errors={errors}
                requiresRP={requiresRP}
              />
            </div>
            {errorUser && (
              <div className='mt-5'>
                <ErrorBox title={errorUser} />
              </div>
            )}

            <div className='row mt-4'>
              <div className='col my-auto'>
                <div className='semi-bold'>{t('signUp.workedBefore')}</div>
              </div>
              <div className='col-auto'>
                <div className={styles.toggleButtons}>
                  <button
                    type='button'
                    className={`btn ${worked === true ? 'btn-primary' : 'btn-secondary'}`}
                    onClick={() => setWorked(true)}
                  >
                    {t('yes')}
                  </button>
                  <button
                    type='button'
                    className={`btn ${worked === false ? 'btn-primary' : 'btn-secondary'}`}
                    onClick={() => setWorked(false)}
                  >
                    {t('no')}
                  </button>
                </div>
              </div>
              {errors.worked && (
                <div className='col-12'>
                  <div className='errorLabel position-relative mt-2'>{errors.worked}</div>
                </div>
              )}
            </div>

            <div className='row mt-4'>
              <div className='col my-auto'>
                <div className='semi-bold'>{t('signUp.familyWorked')}</div>
              </div>
              <div className='col-auto'>
                <div className={styles.toggleButtons}>
                  <button
                    type='button'
                    className={`btn ${familyWorked === true ? 'btn-primary' : 'btn-secondary'}`}
                    onClick={() => setFamilyWorked(true)}
                  >
                    {t('yes')}
                  </button>
                  <button
                    type='button'
                    className={`btn ${familyWorked === false ? 'btn-primary' : 'btn-secondary'}`}
                    onClick={() => setFamilyWorked(false)}
                  >
                    {t('no')}
                  </button>
                </div>
              </div>
              {errors.familyWorked && (
                <div className='col-12'>
                  <div className='errorLabel position-relative mt-2'>{errors.familyWorked}</div>
                </div>
              )}
            </div>

            <div className='mt-4 font-tiny text-secondary'>{t('signUp.confirmText')}</div>
            <div className='mt-4'>
              <Button
                size='large'
                fontSize={'18px'}
                text={
                  verifyingUser ? (
                    <div className='d-flex justify-content-center'>
                      <Spinner size={25} color='#000' />
                    </div>
                  ) : (
                    t('continue')
                  )
                }
                icon={verifyingUser ? false : true}
                typeIcon='arrow'
                iconColor='#000'
              />
            </div>
          </form>
        </div>
      </ContentApp>
    </>
  );
};

export default SignUpEmailValidationScreenRosanegra;
