import React, { useState, useContext } from 'react';
import { WelletContext } from '../../context/WelletContext';
import { signInWithCustomToken } from 'firebase/auth';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { exchangeToken } from '../../actions/associatesActions';
import { getAuth } from 'firebase/auth';
import { getQueryString } from '../../helpers/url';
import { useTranslation } from 'react-i18next';
import ButtonProccessing from '../../components/Common/ButtonProccessing/ButtonProccessing';
import ContentApp from '../../components/ContentApp';
import Header from '../../components/Common/Header/Header';
import ErrorBox from '../../components/Common/ErrorBox/ErrorBox';
import useForm from '../../helpers/useForm';
import InputFormBorder from '../../components/Common/InputFormBorder/InputFormBorder';

const SignUpUserValidationScreen = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const tenant = useSelector((state) => state.app.tenantId);
  const language = useSelector((state) => state.app.language);
  const auth = getAuth();
  const email = getQueryString('email');
  const countryCode = getQueryString('countryCode');
  const phone = getQueryString('phone');
  const sector = getQueryString('industry');
  const jobtitle = getQueryString('role');
  const company = getQueryString('company');
  const hasWorked = JSON.parse(getQueryString('workedBefore'));
  const hasFamily = JSON.parse(getQueryString('familyWorked'));
  const webCompany = getQueryString('webCompany');
  const socialNetworkCompany = getQueryString('socialNetworkCompany');
  const officeAddressCompany = getQueryString('officeAddressCompany');
  const corporateEmail = getQueryString('corporateEmail');
  const rp = getQueryString('rp');
  const token = getQueryString('token');
  const CityId = getQueryString('city');
  const welletContext = useContext(WelletContext);
  const [error, setError] = useState(null);
  const [buttonStatus, setButtonStatus] = useState('NORMAL');

  const onContinueHandle = (user) => {
    exchangeToken(dispatch, user, () => history.push('/kyc-start'));
  };

  function validate(values) {
    let errors = {};
    const rexCapital = /[A-Z]/;
    const rexNumber = /[0-9]/;
    const rexSpace = /\s/;
    if (!values.password) {
      errors.password = t('passwordError.PASSWORD_TOO_SHORT');
    } else if (values.password.length < 8) {
      errors.password = t('passwordError.PASSWORD_TOO_SHORT');
    } else if (!rexCapital.test(values.password)) {
      errors.password = t('passwordError.PASSWORD_HAS_NOT_UPPERCASE_LETTER');
    } else if (!rexNumber.test(values.password)) {
      errors.password = t('passwordError.PASSWORD_HAS_NOT_NUMBER');
    } else if (rexSpace.test(values.password)) {
      errors.password = t('passwordError.PASSWORD_CONTAIN_SPACES');
    } else if (values.password !== values.passwordconfirmation) {
      errors.passwordconfirmation = t('passwordError.PASSWORD_NOT_MATCH');
    }
    return errors;
  }

  const sendForm = async () => {
    setButtonStatus('INPROGRESS');
    setError(null);
    try {
      const result = await welletContext.apis.tickets.post(
        `register/identity`,
        {
          email: email,
          phone: phone,
          password: values.password,
          confirmation: values.passwordconfirmation,
          tenantId: tenant,
          countryCode,
          sector,
          jobtitle,
          Company: company,
          RpId: rp === '0' ? null : rp,
          language,
          CityId: Number(CityId),
          HasWorkedPreviously: hasWorked,
          HasFamilyWorking: hasFamily,
          OfficeAddressCompany: officeAddressCompany,
          SocialNetworkCompany: socialNetworkCompany,
          WebCompany: webCompany,
          CorporateEmail: corporateEmail,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      );
      if (result.data.verification) {
        setButtonStatus('FINISHED');
        const userCredentials = await signInWithCustomToken(auth, result.data.token);
        onContinueHandle(userCredentials.user);
      } else {
        setButtonStatus('NORMAL');
        setError('x');
      }
    } catch (error) {
      setButtonStatus('NORMAL');
      setError('x');
      console.error(error);
    }
  };

  const { values, errors, handleChange, handleSubmit } = useForm(sendForm, validate);

  return (
    <>
      <Header title={''} hasBackground={true} />
      <ContentApp background={true}>
        <div>
          <div className='text-left'>
            <div className='font-medium mt-3 bold' style={{ color: '#fff' }}>
              {t('signUp.choosePassword')}
            </div>
            <div className='mt-2 subtitle font-small' style={{ color: 'var(--color-font-gray)' }}>
              {t('signUp.passwordDescription')}
            </div>
          </div>
          <form className='mt-4' onSubmit={handleSubmit}>
            <div className='mt-4'>
              <InputFormBorder
                name='password'
                type='password'
                value={values.password}
                error={errors.password}
                placeholder={t('password')}
                onChange={handleChange}
              />
            </div>
            <div className='mt-4'>
              <InputFormBorder
                name={'passwordconfirmation'}
                type={'password'}
                value={values.passwordconfirmation}
                error={errors.passwordconfirmation}
                placeholder={t('repeatPassword')}
                onChange={handleChange}
              />
            </div>
            {error ? (
              <div className='mt-3'>
                <ErrorBox title='Error' description={t('passwordError.CHANGE_PASSWORD_TITLE')} />
              </div>
            ) : null}
            <div className='mt-3 d-flex justify-content-center'>
              <ButtonProccessing
                size={'large'}
                text={t('continue')}
                inProgressText='Procesando'
                status={buttonStatus}
                checkSize='30px'
                arrow={true}
                backgroundColor='var(--color-primary)'
                color='#000'
                iconColor='#000'
                className={'d-flex align-items-center justify-content-center'}
              />
            </div>
          </form>
        </div>
      </ContentApp>
    </>
  );
};

export default SignUpUserValidationScreen;
