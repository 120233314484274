import React, { useContext, useEffect, useState } from 'react';
import Select, { components } from 'react-select';
import { useTranslation } from 'react-i18next';
import { WelletContext } from '../../../context/WelletContext';
import SolidChevron from '../../icons/SolidChevron';
import styles from '../../../screens/SignUp/SignUp.module.css';
import InputFormBorder from '../InputFormBorder/InputFormBorder';

const IndustryRoleSelectorRosaNegra = ({
  handleChange,
  selectedIndustry,
  setSelectedIndustry,
  selectedRp,
  setSelectedRp,
  selectedCity,
  setSelectedCity,
  requiresRP,
  errors,
  values,
}) => {
  const { t } = useTranslation();
  const [rps, setRps] = useState([]);
  const welletContext = useContext(WelletContext);
  const withoutRP = { value: '0', label: t('signUp.withOutRP') };
  const hasNoRp = selectedRp.value === '0';

  const handleChangeIndustry = (selectedOption) => {
    handleChange({ target: { name: 'industry', value: selectedOption.label }, persist: () => {} });
    setSelectedIndustry(selectedOption.label);
  };

  const handleChangeRP = (selectedOption) => {
    handleChange({ target: { name: 'rp', value: selectedOption.value }, persist: () => {} });
    setSelectedRp(selectedOption);
  };

  const handleChangeCity = (selectedOption) => {
    handleChange({ target: { name: 'city', value: selectedOption.value }, persist: () => {} });
    setSelectedCity(selectedOption);
  };

  const getRP = async () => {
    try {
      const result = await welletContext.apis.tickets.get(`associates/GetRPs`);
      setRps(result.data.map((item) => ({ value: item.id, label: item.name })));
      setRps((rps) => [...rps, withoutRP]);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getRP();
  }, []);

  const DropdownIndicator = (props) => {
    return (
      <components.DropdownIndicator {...props} className='mr-3 p-0'>
        <SolidChevron size={18} color='var(--color-primary)' />
      </components.DropdownIndicator>
    );
  };

  const industryOptions = [
    { value: 'hotel', label: t('industry.hotel') },
    { value: 'agency', label: t('industry.agency') },
    { value: 'dmc', label: t('industry.dmc') },
    { value: 'mp', label: t('industry.meetingPlanner') },
    { value: 'wp', label: t('industry.weddingPlanner') },
    { value: 'conveyor', label: t('industry.transportation') },
    { value: 'bank', label: t('industry.bank') },
    { value: 'airbnb', label: t('industry.airbnb') },
    { value: 'yates', label: t('industry.yachts') },
  ];

  const cityOptions = [
    {
      value: 935,
      label: 'Cancún',
    },
    {
      value: 2265,
      label: 'Ciudad de México',
    },
    {
      value: 7925,
      label: 'Isla Mujeres',
    },
    {
      value: 182189,
      label: 'Tulum',
    },
    {
      value: 9631,
      label: 'Los Cabos',
    },
  ];

  const selectorStyles = {
    control: (provided) => ({
      ...provided,
      paddingLeft: '.4rem',
      boxShadow: 'none',
      backgroundColor: '#000',
      border: 'none',
      borderRadius: '10px',
      height: '61.38px',
    }),
    valueContainer: (provided) => ({
      ...provided,
      boxShadow: 'none',
    }),
    singleValue: (provided) => ({
      ...provided,
      color: '#000',
      fontWeight: 'bold',
    }),
    menu: (provided) => ({
      ...provided,
      bottom: 'auto',
      top: 65,
      color: '#000',
      zIndex: 9999,
    }),
  };

  const handleTouchStart = (event) => {
    event.preventDefault();
  };

  return (
    <>
      <div>
        <div className={``}>
          <InputFormBorder
            name='company'
            placeholder={t('signUp.companyPlaceholder')}
            value={values.company}
            error={errors.company}
            label={t('signUp.company')}
            onChange={handleChange}
            type='text'
          />
        </div>
        <div
          className='field-border-container mt-4'
          style={{
            zIndex: '3',
          }}
        >
          <div
            className='field-border p-0'
            style={{
              border: errors.industry ? '1px solid #FF0000' : '1px solid var(--color-primary)',
            }}
          >
            <div className='field-border-name' style={{ zIndex: '3' }}>
              {t('signUp.sector')}
            </div>
            <Select
              className={`w-100 hide-indicator-separator font-normal`}
              components={{ DropdownIndicator }}
              isSearchable={false}
              styles={selectorStyles}
              options={industryOptions}
              formatOptionLabel={(o) => (
                <div className='option-inner d-flex py-3'>
                  <span>{o.label}</span>
                </div>
              )}
              onChange={handleChangeIndustry}
              placeholder={selectedIndustry}
              value={values.industry}
            />
            {errors.industry && (
              <div className='errorLabel mt-4' style={{ bottom: '-1.6rem' }}>
                {errors.industry}
              </div>
            )}
          </div>
        </div>
      </div>
      {values.industry === 'Hotel' ? (
        <div className={`${errors.industry ? 'mt-5' : 'mt-4'}`}>
          <InputFormBorder
            name='corporateEmail'
            placeholder={t('signUp.corporateEmail')}
            value={values.corporateEmail || null}
            error={errors.corporateEmail}
            label={t('signUp.corporateEmail')}
            onChange={handleChange}
            type='text'
          />
        </div>
      ) : values.industry?.length > 0 ? (
        <>
          <div className={`${errors.industry ? 'mt-5' : 'mt-4'}`}>
            <InputFormBorder
              name='webCompany'
              placeholder='URL'
              value={values.webCompany}
              error={errors.webCompany}
              label={t('signUp.webCompany')}
              onChange={handleChange}
              type='text'
            />
          </div>
          <div className={`${errors.industry ? 'mt-5' : 'mt-4'}`}>
            <InputFormBorder
              name='socialNetworkCompany'
              placeholder='URL'
              value={values.socialNetworkCompany}
              error={errors.socialNetworkCompany}
              label='Instagram'
              onChange={handleChange}
              type='text'
            />
          </div>
          <div className={`${errors.industry ? 'mt-5' : 'mt-4'}`}>
            <InputFormBorder
              name='officeAddressCompany'
              placeholder={t('signUp.companyAddress')}
              value={values.officeAddressCompany}
              error={errors.officeAddressCompany}
              label={t('signUp.officeAddressCompany')}
              onChange={handleChange}
              type='text'
            />
          </div>
        </>
      ) : null}

      <div className={`${errors.industry ? 'mt-5' : 'mt-4'}`}>
        <InputFormBorder
          name='role'
          placeholder={t('signUp.role')}
          value={values.role}
          label='Rol'
          error={errors.role}
          onChange={handleChange}
          type='text'
        />
      </div>
      {requiresRP && (
        <>
          <div className={`field-border-container mt-4 ${errors.rp ? 'error' : ''}`}>
            <div className='field-border p-0'>
              <div className='field-border-name' style={{ zIndex: '2' }}>
                {t('signUp.digitalRP')}
              </div>

              <Select
                className={`w-100 mt-0 hide-indicator-separator font-normal`}
                components={{ DropdownIndicator }}
                isSearchable={false}
                styles={selectorStyles}
                options={rps}
                onChange={handleChangeRP}
                placeholder={selectedRp.label}
                value={values.rp}
                formatOptionLabel={(o) => (
                  <div className='option-inner d-flex py-3'>
                    <span>{o.label}</span>
                  </div>
                )}
              />
            </div>
            {errors.rp && <div className='errorLabel'>{errors.rp}</div>}
          </div>
        </>
      )}
      {hasNoRp && (
        <>
          <div>
            <div className={`field-border-container mt-4 ${errors.city ? 'error' : ''}`}>
              <div className='field-border p-0'>
                <div className='field-border-name' style={{ zIndex: '4' }}>
                  {t('signUp.cityOfOperation')}
                </div>

                <Select
                  className={`w-100 mt-0 hide-indicator-separator font-normal`}
                  components={{ DropdownIndicator }}
                  isSearchable={false}
                  onTouchStart={handleTouchStart}
                  styles={selectorStyles}
                  options={cityOptions}
                  onChange={handleChangeCity}
                  placeholder={selectedCity.label}
                  value={values.city}
                  formatOptionLabel={(o) => (
                    <div className='option-inner d-flex py-3'>
                      <span>{o.label}</span>
                    </div>
                  )}
                />
              </div>
              {errors.city && <div className='errorLabel'>{errors.city}</div>}
            </div>
          </div>
        </>
      )}
      {errors.rps && errors.rps > 0 && (
        <div className={`${styles.errorLabel}`}>
          <span></span> {errors.rps}
        </div>
      )}
    </>
  );
};

export default IndustryRoleSelectorRosaNegra;
